<template>
  <!--  For each View component, follow Vuetify <v-container> => <v-row> => <v-col> grid system  -->
  <v-container
    fluid
    class="mt-12"
  >
    <v-row>
      <v-col class="text-center">
        <h1 class="display-2 font-weight-bold mb-3">Error Page</h1>
      </v-col>
    </v-row>
    <v-row>
      <p>{{ errorMessage }}</p>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'About',
  /**
   *  The title option is used by titleMixin for implementing dynamic <title> in SPA for accessibility
   *  Recommended title for each 'view/page' is 'Page name - Site Name'.
   */
  title: 'Error - SAIS Operation UI Portal',
  computed: {
    errorMessage() {
      return this.$store.state.errorMessage
    },
  },
}
</script>

<style lang="scss" scoped>
// use pre-defined ADS scss variables
@import '../../scss/variables';

.ads-link {
  color: $ads-red-2-dark !important;
}
</style>
